import React from "react"
import Layout from "../../components/layout"
import Footer from "../../components/Footer"
import { Tab } from "@headlessui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { useStaticQuery, graphql } from "gatsby"
import Leadership from "../../components/Leadership"
import Management from "../../components/Management"
import PageHeader from "../../components/PageHeader"
import Seo from "../../components/seo"
import GovernanceTable from "../../components/investors/GovernanceTable"
import Link from "../../components/Link"

export default function CorporateGovernancePage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 254 }) {
        id
        title
        articlesOfAssociation {
          text
        }
        generalMeetings {
          meetings {
            title
            documentsMeeting {
              titleMeetingFile
              fileMeetingFile {
                mediaItemUrl
              }
            }
          }
        }
        boardMembers {
          member {
            bio
            name
            shareholding
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const pageTitle = "Corporate Governance"
  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />{" "}
      <div className=" text-base py-8 md:py-16">
        <PageHeader text="Corporate Governance" />
        <div>
          <Tabs data={data} />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

function TabButton({ text, selected }) {
  return (
    <button
      className={`${
        selected === true ? "text-gray-700" : "text-white"
      } bg-primary  py-2 md:py-3 px-3 md:px-4 rounded-full  text-sm md:text-lg focus:outline-none `}
    >
      {text}
    </button>
  )
}
function Tabs({ data }) {
  const articlesOfAssociation = data.wpPage.articlesOfAssociation.text
  const meetings = data.wpPage.generalMeetings.meetings
  return (
    <div>
      <Tab.Group>
        <div className="max-w-4xl mx-auto">
          <Tab.List className="flex flex-col md:flex-row items-center justify-around mb-8 md:mb-16 space-y-2">
            <Tab as="div">
              {({ selected }) => (
                <TabButton text="Board Of Directors" selected={selected} />
              )}
            </Tab>
            <Tab as="div">
              {({ selected }) => (
                <TabButton text="Management" selected={selected} />
              )}
            </Tab>
            <Tab as="div">
              {({ selected }) => (
                <TabButton text="Articles of Association" selected={selected} />
              )}
            </Tab>
            <Tab as="div">
              {({ selected }) => (
                <TabButton text="General Meetings" selected={selected} />
              )}
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels>
          <Tab.Panel>
            <Board data={data} />
          </Tab.Panel>
          <Tab.Panel>
            <div className="max-w-4xl mx-auto">
              <Management />
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="max-w-4xl mx-auto px-4">
              <div
                dangerouslySetInnerHTML={{ __html: articlesOfAssociation }}
                className="space-y-4"
              />
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="max-w-4xl mx-auto">
              <GeneralMeetings meetings={meetings} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

function Board({ data }) {
  const boardmembers = data.wpPage.boardMembers.member
  return (
    <div className="px-4">
      <div className="max-w-4xl mx-auto">
        {boardmembers.map(member => {
          return <BoardMember key={member.id} member={member} />
        })}
      </div>
      <div className="max-w-7xl mx-auto px-4">
        <GovernanceTable />
      </div>
    </div>
  )
}
function BoardMember({ member }) {
  const { name, title, bio, image, shareholding } = member
  return (
    <div className="mb-8">
      <div className="grid md:grid-cols-2 gap-8 items-center">
        <div className="p-4">
          <GatsbyImage
            image={getImage(image?.localFile.childImageSharp)}
            alt={member.name}
          />
        </div>
        <div className="space-y-4 px-4">
          <div>
            <h3 className="text-2xl font-bold">{name}</h3>
            <p>{title}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: bio }}
            className="space-y-4"
          />
          <div>
            <p>Shareholding: {shareholding}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function GeneralMeetings({ meetings }) {
  return (
    <div className="flex flex-col gap-8">
      {meetings.map((meeting, i) => {
        return <Meeting key={i} meeting={meeting} />
      })}
    </div>
  )
}

function Meeting({ meeting }) {
  const { title, documentsMeeting } = meeting
  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-bold">{title}</h3>
      <div>
        <p>Documents:</p>
        {documentsMeeting.map((document, i) => {
          return <Document key={document.id} document={document} />
        })}
      </div>
    </div>
  )
}

function Document({ document }) {
  const { titleMeetingFile, fileMeetingFile } = document
  return (
    <div className="flex items-center space-x-4">
      <div>
        <p>
          <Link to={fileMeetingFile.mediaItemUrl} title="File">
            {titleMeetingFile}
          </Link>
        </p>
      </div>
    </div>
  )
}
