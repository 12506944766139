import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Management() {
  const data = useStaticQuery(graphql`
    {
      wpPage(slug: { eq: "about" }) {
        id
        title
        content
        slug
        uri
        team {
          teamMember {
            bio
            name
            shareholding
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1

                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            title
          }
        }
      }
    }
  `)
  const teamMembers = data.wpPage.team.teamMember

  return (
    <div className=" py-8 px-4">
      {" "}
      <section className="team">
        <div className="flex flex-col w-full mb-16 ">
          {teamMembers.map(member => {
            return <TeamMember key={member.name} member={member} />
          })}
        </div>
      </section>
    </div>
  )
}

function TeamMember({ member }) {
  const { name, title, bio, shareholding } = member
  return (
    <div className="grid md:grid-cols-2 gap-8 items-center mb-8">
      <div className="p-2">
        <GatsbyImage
          image={getImage(member.photo.localFile.childImageSharp)}
          alt={member.name}
        />
      </div>
      <div className="space-y-4 px-2">
        <div>
          <h3 className="text-2xl font-bold">{name}</h3>
          <p>{title}</p>
        </div>

        <div>
          <div dangerouslySetInnerHTML={{ __html: bio }} />
        </div>
        <div>
          <p>Shareholding: {shareholding}</p>
        </div>
      </div>
    </div>
  )
}
