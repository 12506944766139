import React from "react"
import { useStaticQuery, graphql } from "gatsby"
export default function GovernanceTable() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 254 }) {
        id
        stockTable {
          stockOwners {
            companyManagement
            dob
            elected
            majorShareholder
            name
            position
            shareholding
          }
        }
      }
    }
  `)

  const owners = data.wpPage.stockTable.stockOwners

  return (
    <div className="overflow-x-auto">
      <table className="table-auto whitespace-nowrap text-left governance w-full">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colSpan="2" className="text-center">
              Independent in relation to
            </th>

            <th></th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>DOB</th>
            <th>Elected</th>
            <th>Company management</th>
            <th>Major Shareholder</th>
            <th>Shareholding</th>
          </tr>
        </thead>
        <tbody>
          {owners.map(owner => {
            return <Owner key={owner.id} owner={owner} />
          })}
        </tbody>
      </table>
    </div>
  )
}

function Owner({ owner }) {
  const {
    name,
    position,
    dob,
    elected,
    companyManagement,
    majorShareholder,
    shareholding,
  } = owner
  return (
    <tr>
      <td>{name}</td>
      <td>{position}</td>
      <td>{dob}</td>
      <td>{elected}</td>
      <td>{companyManagement}</td>
      <td>{majorShareholder}</td>
      <td>{shareholding}</td>
    </tr>
  )
}
